@use "variables";
@import "bootstrap/functions";

$primary: variables.$primary;
$secondary: variables.$secondary;

* {
  box-sizing: border-box;
}

@import "bootstrap/bootstrap.scss";

.full-container {
  gap: 0.3rem;
}

.main-content {
  margin: 1rem;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  @media only screen and (min-width: 850px) {
    width: 70%;
  }
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.sidebar {
  background-color: variables.$sidebar-background-color;
}


blockquote {
  border-left: 0.25em solid #dfe2e577;
  color: #6a737d;
  padding: 0 1em;
}

.post-img {
  width: 100%;
  max-width: 400px;
}

.post-img-large {
  width: 100%;
  max-width: 600px;
}

td, th {
   border: 1px solid rgb(155, 155, 155);
   padding: 10px;
}
